var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"inventory-transfer__filters-container"},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"fill-height":"","justify-start":"","align-center":"","align-content-center":""}},[_c('div',{staticClass:"picker-container"},[_c('HelixDatePickerComponent',{staticClass:"mr-3",attrs:{"type":"datetime","options":{
              placeholder: _vm.$t(
                'batch_transfer_manager_module.labels.departure'
              ).toString()
            }},on:{"input":_vm.filter},model:{value:(_vm.filters.departure),callback:function ($$v) {_vm.$set(_vm.filters, "departure", $$v)},expression:"filters.departure"}})],1),_vm._v(" "),_c('div',{staticClass:"picker-container"},[_c('HelixDatePickerComponent',{staticClass:"mr-3",attrs:{"type":"datetime","options":{
              placeholder: _vm.$t(
                'batch_transfer_manager_module.labels.arrival'
              ).toString()
            }},on:{"input":_vm.filter},model:{value:(_vm.filters.arrival),callback:function ($$v) {_vm.$set(_vm.filters, "arrival", $$v)},expression:"filters.arrival"}})],1)])],1)],1),_vm._v(" "),_c('table-component',{attrs:{"data":_vm.tableDefs.data,"headers":_vm.tableDefs.headers,"loading":_vm.tableDefs.loading,"expand":true,"selectBy":'destinations',"rowActions":_vm.rowActions,"pagination":_vm.pagination},on:{"expand":_vm.loadTransfers,"changePagination":_vm.changePagination,"filterParams":_vm.sort},scopedSlots:_vm._u([{key:"default",fn:function(row){return _c('div',{},[_c('TableSubListComponent',{attrs:{"list":row.data.destinations,"fieldsToShow":_vm.tableDefs.subcomponent.fieldsToShow,"loading":_vm.tableDefs.subcomponent.loading}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }