import ComputeTransfer from "@/components/inventory/BatchTransfer/BatchTransferManager/ComputeTransfer.mixin";
import {
  BatchTransfer,
  TRANSFER_TYPES
} from "@/interfaces/batchTransferManager";
import VueBarcode from "vue-barcode";
import { Component, Mixins, Prop } from "vue-property-decorator";
import Template from "./InboundTransfersPrint.template.vue";

@Component({
  mixins: [Template],
  components: {
    VueBarcode
  }
})
export default class InboundTransfersPrint extends Mixins(ComputeTransfer) {
  @Prop() public inbound!: BatchTransfer.Inbound;
  public calculatedTransfer: BatchTransfer.Transfer | null = null;
  public groupedPayments: Array<{
    id: number;
    value: number;
    name: string;
  }> = [];

  public get transfer(): BatchTransfer.Transfer | null {
    return this.calculatedTransfer;
  }
  public get payments() {
    return this.groupedPayments.filter(payment => payment.value > 0);
  }
  public get numberOfBatches() {
    return this.transfer!.items.length;
  }
  public get buyer() {
    if (this.transfer!.transfer_type === TRANSFER_TYPES.INBOUND) {
      return (this.transfer! as BatchTransfer.InboundTransfer).location;
    } else {
      return (this.transfer! as BatchTransfer.OutboundTransfer).destination;
    }
  }
  public get seller() {
    if (this.transfer!.transfer_type === TRANSFER_TYPES.INBOUND) {
      return (this.transfer! as BatchTransfer.InboundTransfer).source;
    } else {
      return (this.transfer! as BatchTransfer.OutboundTransfer).location;
    }
  }
  public get barcodeStyles() {
    return {
      height: 24,
      lineColor: "#525252"
    };
  }
  protected async mounted() {
    this.groupedPayments.forEach(payment => (payment.value = 0));
    const transfer = {
      ...this.inbound,
      transfer: { items: this.inbound.items }
    } as any;
    this.calculatedTransfer = this.recalculateAll(transfer);

    this.inbound.payments.forEach(inboundPayment => {
      const relatedPayment = this.groupedPayments.find(
        p => p.id === inboundPayment.type
      );
      if (relatedPayment) {
        relatedPayment.value += Number(inboundPayment.applied);
      }
    });
    this.$emit("readyToPrint");
  }
}
