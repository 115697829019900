import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import { currencyFilter } from "@/filters/currency.filter";
import { InventoryBatch } from "@/interfaces/batch";
import {
  BatchTransfer,
  SourceType,
  TRANSFER_SOURCE,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { Location } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { Vendor } from "@/interfaces/vendor";
import { EventBus, pusherEvents, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import batchTransferManagerService from "@/services/BatchTransferManager/batchTransferManager.service";
import { InboundTransferService } from "@/services/BatchTransferManager/InboundTransfer.service";
import invoiceService, {
  InvoiceService
} from "@/services/BatchTransferManager/Invoice.service";
import {
  HelixDatePickerComponent,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault,
  TableSubListComponent
} from "helix-vue-components";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import InvoicePrint from "../../Invoice/InvoicePrint/InvoicePrint.component";
import BatchTransferTableActions from "../BatchTransferTableActions.mixin";
import BatchTransferTableData from "../BatchTransferTableData.mixin";
import InboundTransfersPrint from "../InboundTransfersDataTable/print/InboundTransfersPrint.component";
import Template from "./InboundTransfersDataTable.template.vue";
import PrintLabel from "./printLabel/PrintLabel.component";
import { inboundTransferTable } from "./tableDefinition";
@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableSubListComponent,
    HelixDatePickerComponent,
    PrintLabel
  },
  inject: ["$changes"]
})
export default class InboundTransfersDataTable extends Mixins(
  BatchTransferTableData,
  BatchTransferTableActions,
  StepsData
) {
  @Prop({ default: false }) public isActive!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  public sourceTypes: SourceType[] = [];
  public filters: { [key: string]: any } = {};
  public pagination: TablePagination = { ...TablePaginationDefault };
  public inboundTypes: Array<{ label: string; value: string }> = [];
  public inboundStatuses: Array<{ label: string; value: string }> = [];
  public searchField = "";
  public isLoading = false;
  public destinationTypes: Array<{
    label: string;
    value: TRANSFER_SOURCE;
  }> = [];

  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  protected currentSort: string | null = null;
  protected loaded: boolean = false;
  protected service!: InboundTransferService;
  protected invService!: InvoiceService;
  protected inbound: BatchTransfer.Inbound[] = [];
  protected defaultQuery: { [key: string]: string | number } = {
    sort: "-updated_at",
    "q[status_noteq]": TRANSFER_STATUS.CANCELED
  };
  private sourceScope: BatchTransfer.FilterScopeTransfer =
    BatchTransfer.defaultFilterScope;

  public get rowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-ellipsis-v",
        modalActions: {
          modalNumber: 2,
          modalButtons: [
            {
              icon: "fal fa-print",
              visibleCondition: () =>
                this.hasPermission(this.policyList.printNewBatchesLabels),
              action: async (item: any) => {
                store.dispatch("RouterModule/go", {
                  name: "inbound-transfer-print-label",
                  params: {
                    data: item
                  }
                });
              }
            },
            {
              icon: "fal fa-file-invoice-dollar",
              action: async (item: BatchTransfer.Inbound) => {
                const inboundItem = this.rawData.find(t => item.id === t.id);
                let inbound = await this.service.getById(inboundItem.id, {
                  embed:
                    "sourceable,location,payments.paymentMethod,transfer.transferItems.batch.product,transfer.sourceable,transfer.location"
                });

                if (inbound.is_payable && inbound.invoice_id) {
                  let invoice = await this.invService.getById(
                    inbound.id!,
                    inbound.invoice_id
                  );
                  if (!invoice.transfer!.source) {
                    invoice = this.setInvoiceSource(invoice);
                  }

                  EventBus.$emit("print", {
                    component: InvoicePrint,
                    props: {
                      invoice
                    }
                  });
                } else {
                  if (!inbound.source) {
                    inbound = this.setInboundSource(inbound);
                  }
                  EventBus.$emit("print", {
                    component: InboundTransfersPrint,
                    props: {
                      inbound
                    }
                  });
                }
              }
            },
            {
              icon: "fal fa-pen",
              visibleCondition: item =>
                this.hasPermission(this.policyList.modifyInboundTransfer) &&
                item.source_type !== "LOCATION_TRANSFER",
              action: (item: BatchTransfer.Inbound) => {
                const transfer = this.rawData.find(t => item.id === t.id);
                this.edit(transfer);
              }
            },
            {
              icon: "fal fa-trash-alt",
              action: (item: BatchTransfer.Inbound) => {
                this.void(item.id);
              },
              visibleCondition: (item: BatchTransfer.InboundTransfer) => {
                if (
                  !!item.is_voidable &&
                  this.hasPermission(this.policyList.voidInboundTransfer)
                ) {
                  if (item.source_type === "LOCATION_TRANSFER") {
                    return item.source_id === item.location_id;
                  }
                  return true;
                }
                return false;
              }
            }
          ]
        }
      }
    ];
  }

  public get source() {
    const sources: { [key: string]: any } = {
      INITIAL_INVENTORY_WITHOUT_VENDOR: {
        placeholder: i18n.t(
          "batch_transfer_manager_module.labels.new/existing_initial_inventory_without_vendor"
        ),
        items:
          (this.sourceScope.locations.length && this.sourceScope.locations) ||
          this.defaultListLocations
      },
      INITIAL_INVENTORY_WITH_VENDOR: {
        placeholder: i18n.t(
          "batch_transfer_manager_module.labels.new/existing_initial_inventory_from_vendor"
        ),
        items:
          (this.sourceScope.vendors.length && this.sourceScope.vendors) ||
          this.defaultListVendors
      },
      NON_CANNABIS_INVENTORY_WITHOUT_VENDOR: {
        placeholder: i18n.t(
          "batch_transfer_manager_module.labels.new/existing_non_cannabis_inventory_without_vendor"
        ),
        items:
          (this.sourceScope.locations.length && this.sourceScope.locations) ||
          this.defaultListLocations
      },
      INVENTORY_WITHOUT_VENDOR: {
        placeholder: i18n.t(
          "batch_transfer_manager_module.labels.existing_inventory"
        ),
        items:
          (this.sourceScope.locations.length && this.sourceScope.locations) ||
          this.defaultListLocations
      },
      NON_CANNABIS_INVENTORY_WITH_VENDOR: {
        placeholder: i18n.t(
          "batch_transfer_manager_module.labels.new/existing_non_cannabis_inventory_from_vendor"
        ),
        items:
          (this.sourceScope.vendors.length && this.sourceScope.vendors) ||
          this.defaultListVendors
      },
      EXISTING_CANNABIS_INVENTORY_IN_TRACEABILITY: {
        placeholder: i18n.t(
          "batch_transfer_manager_module.labels.create_existing_cannabis_inventory_tracebility"
        ),
        items:
          (this.sourceScope.locations.length && this.sourceScope.locations) ||
          this.defaultListLocations
      },
      INVENTORY_WITH_VENDOR: {
        placeholder: i18n.t(
          "batch_transfer_manager_module.labels.new/existing_inventory_from_vendor"
        ),
        items:
          (this.sourceScope.vendors.length && this.sourceScope.vendors) ||
          this.defaultListVendors
      }
    };
    return this.filters.source_type
      ? sources[this.filters.source_type]
      : {
          placeholder: i18n.t(
            "batch_transfer_manager_module.labels.select_type_first"
          ),
          items: []
        };
  }

  public daysCounter(days: number) {
    const inventoryStartingDate: Date =
      this.currentRetailSettings!.integrations! &&
      this.currentRetailSettings!.integrations!.biotrack_traceability! &&
      this.currentRetailSettings!.integrations!.biotrack_traceability!
        .starting_inventory_date!;
    const date = new Date(inventoryStartingDate);
    const differnceInMS = Date.now() - date.getTime();
    const remainingDays = days - Math.floor(differnceInMS / (1000 * 3600 * 24));
    return remainingDays;
  }
  public get remainingDaysForTrace() {
    const dayCount =
      this.currentLocation.state!.name === "Connecticut"
        ? this.daysCounter(30)
        : this.daysCounter(15);
    return dayCount;
  }

  public setInvoiceSource(item: BatchTransfer.Invoice): BatchTransfer.Invoice {
    if (item.transfer!.source_type === "VENDOR_TRANSFER") {
      item.transfer!.source = {
        ...this.defaultListVendors.filter(
          vendor => vendor.id === item.transfer!.source_id!
        )[0]
      } as Vendor;
    } else {
      item.transfer!.source = {
        ...this.defaultListLocations.filter(
          location => location.id === item.transfer!.source_id!
        )[0]
      } as Location;
    }
    return item;
  }

  public setInboundSource(
    item: BatchTransfer.InboundTransfer
  ): BatchTransfer.InboundTransfer {
    if (item.source_type === "VENDOR_TRANSFER") {
      item.source = {
        ...this.defaultListVendors.filter(
          vendor => vendor.id === item.source_id
        )[0]
      } as Vendor;
    } else {
      item.source = {
        ...this.defaultListLocations.filter(
          location => location.id === item.source_id
        )[0]
      } as Location;
    }
    return item;
  }

  public clearSourceFilter() {
    this.searchField = "";
    this.filters.source = null;
    this.filter();
  }
  public updateSource() {
    if (
      this.filters.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER ||
      this.filters.source_type === TRANSFER_SOURCE.EXISTING_INVENTORY
    ) {
      this.sourceScope.locations = this.getterLocations;
    } else {
      this.sourceScope.vendors = this.getterVendors;
    }
    this.isLoading = false;
  }

  @Watch("searchField")
  public async search(value: string) {
    if (value !== null) {
      this.isLoading = true;
      await this.loadDebounce(
        value,
        (this.filters.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER &&
          this.searchLocation) ||
          this.searchVendor,
        this.updateSource
      );
    }
  }

  @Watch("isActive", { immediate: true }) public onActiveChange() {
    if (this.isActive) {
      this.$changes.watch(pusherEvents.transferTouched, this.fetchData);

      if (!this.loaded) {
        this.fetchData();
        this.loaded = true;
      }
    }
  }

  public async loadItems(data: { index: number }) {
    if (!this.tableDefs.data[data.index].visited) {
      this.tableDefs.subcomponent.loading = true;
      const order = this.tableDefs.data[data.index];
      const detail: BatchTransfer.InboundTransfer = await this.service.getById(
        String(order.id),
        {}
      );
      this.tableDefs.data[data.index].items = detail.items.map(item => {
        if (item.batch!) {
          if (
            this.bioTrackTraceEnabled &&
            item.batch!.biotrack_traceability_id
          ) {
            item.batch_uid = item.batch!.biotrack_traceability_id;
          } else {
            item.batch_uid = item.batch!.batch_uid;
          }
        }
        return {
          batch_id: item.batch_uid,
          product: item.product!.name,
          batch_type: item.product!.batch_type!.name,
          quantity: item.quantity,
          total: currencyFilter(item.prices.total),
          id: item.id,
          batch_uid: item.batch_uid,
          new_product: item.product,
          unit: item.product!.unit,
          price: item.prices.cost_per_unit,
          new_batch_id:
            (item.batch &&
              (item.batch as InventoryBatch).biotrack_traceability_id) ||
            ""
        };
      });
      this.tableDefs.subcomponent.loading = false;
      this.tableDefs.data[data.index].visited = true;
    }
  }

  public sort(header: TableHeader) {
    this.sortAndFetch(header, this.fetchData);
  }

  protected mounted() {
    this.invService = invoiceService;
    this.service = batchTransferManagerService.service(
      "inbound-transfer"
    ) as InboundTransferService;
    this.sourceTypes = [
      {
        label: String(
          i18n.t(
            "batch_transfer_manager_module.labels.new/existing_initial_inventory_without_vendor"
          )
        ),
        value: "INITIAL_INVENTORY_WITHOUT_VENDOR"
      },
      {
        label: String(
          i18n.t(
            "batch_transfer_manager_module.labels.new/existing_initial_inventory_from_vendor"
          )
        ),
        value: "INITIAL_INVENTORY_WITH_VENDOR"
      },
      {
        label: String(
          i18n.t(
            "batch_transfer_manager_module.labels.new/existing_non_cannabis_inventory_without_vendor"
          )
        ),
        value: "NON_CANNABIS_INVENTORY_WITHOUT_VENDOR"
      },
      {
        label: String(
          i18n.t("batch_transfer_manager_module.labels.existing_inventory")
        ),
        value: "INVENTORY_WITHOUT_VENDOR"
      },
      {
        label: String(
          i18n.t(
            "batch_transfer_manager_module.labels.new/existing_non_cannabis_inventory_from_vendor"
          )
        ),
        value: "NON_CANNABIS_INVENTORY_WITH_VENDOR"
      },
      {
        label: String(
          i18n.t(
            "batch_transfer_manager_module.labels.create_existing_cannabis_inventory_tracebility"
          )
        ),
        value: "EXISTING_CANNABIS_INVENTORY_IN_TRACEABILITY"
      },
      {
        label: String(
          i18n.t(
            "batch_transfer_manager_module.labels.new/existing_inventory_from_vendor"
          )
        ),
        value: "INVENTORY_WITH_VENDOR"
      }
    ];
  }

  protected changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.applyFilters();
    this.loaded = true;
  }

  protected async applyFilters() {
    if (this.filters.source_type === TRANSFER_SOURCE.EXISTING_INVENTORY) {
      this.query = {
        sourceable_type: "LOCATION_TRANSFER",
        "q[sourceable_id_equals]": `${this.currentLocation.id}`,
        "q[location_id_equals]": `${this.currentLocation.id}`,
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage
      };
    } else if (this.filters.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER) {
      this.query = {
        sourceable_type: "LOCATION_TRANSFER",
        "q[sourceable_id_is_not_in]": [`${this.currentLocation.id}`],
        "q[location_id_equals]": `${this.currentLocation.id}`,
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage
      };
    } else {
      this.query = {
        "q[updated_at_greater_than_or_equal]": this.filters.start_date,
        "q[updated_at_less_than_or_equal]": this.filters.end_date,
        "q[sourceable_transfer_type_equals]": this.filters.source_type,
        "q[sourceable_id_equals]": this.filters.source_id,
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage
      };
    }

    await this.fetchData();
    this.tableDefs.loading = false;
  }

  protected async fetchData() {
    this.tableDefs.loading = true;
    const response = await this.service.getAll({
      ...this.defaultQuery,
      ...this.query
    });
    this.pagination = response.pagination;
    this.rawData = response.data;
    this.mapData(inboundTransferTable, this.rawData);
    this.tableDefs.loading = false;
  }

  protected async void(id: number) {
    const response = await this.service.void(id);
    if (response) {
      EventBus.$emit("BatchTransferListTouched", "inbound-transfer");
      this.tableDefs.loading = true;
      await this.fetchData();
    }
    this.tableDefs.loading = false;
  }
}
