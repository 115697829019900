import BatchTransferTableActions from "@/components/inventory/BatchTransfer/BatchTransferList//BatchTransferTableActions.mixin";
import BatchTransferTableData from "@/components/inventory/BatchTransfer/BatchTransferList/BatchTransferTableData.mixin";
import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import {
  BatchTransfer,
  TRANSFER_SOURCE,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { Location } from "@/interfaces/location";
import { Vendor } from "@/interfaces/vendor";
import { EventBus, pusherEvents } from "@/internal";
import invoiceService, {
  InvoiceService
} from "@/services/BatchTransferManager/Invoice.service";
import { messagesService } from "@/services/messages.service";
import {
  HelixDatePickerComponent,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import InvoicePrint from "../../Invoice/InvoicePrint/InvoicePrint.component";
import InvoiceDataTableDetailsComponent from "./InvoiceDataTableDetails/InvoiceDataTableDetails.component";
import Template from "./InvoicesDataTable.template.vue";
import { invoicesTable } from "./tableDefinitions";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    HelixDatePickerComponent,
    InvoiceDataTableDetailsComponent
  },
  inject: ["$changes"]
})
export default class InvoicesDataTable extends Mixins(
  BatchTransferTableData,
  BatchTransferTableActions,
  StepsData
) {
  public get rowActions() {
    return [
      {
        icon: "fal fa-ellipsis-v",
        modalActions: {
          modalNumber: 2,
          modalButtons: [
            {
              icon: "fal fa-usd-circle",
              action: (item: any) => {
                const invoice = this.rawData.find(t => item.id === t.id);
                this.$router.push({
                  name: "invoice_add_payment",
                  params: {
                    transfer_id: invoice.transfer_id,
                    invoice_id: invoice.id
                  }
                });
              },
              visibleCondition: (item: any) => {
                return (
                  item.status !== TRANSFER_STATUS.COMPLETE &&
                  this.hasPermission(this.policyList.addPayments)
                );
              }
            },
            {
              icon: "fal fa-file-invoice-dollar",
              visibleCondition: () =>
                this.hasPermission(this.policyList.printInvoices),
              action: async (item: any) => {
                const invoiceItem = this.rawData.find(t => item.id === t.id);
                let invoice = await this.service.getById(
                  invoiceItem.transfer_id,
                  invoiceItem.id
                );

                if (!invoice.transfer!.source) {
                  invoice = this.setInvoiceSource(invoice);
                }

                EventBus.$emit("print", {
                  component: InvoicePrint,
                  props: {
                    invoice
                  }
                });
              }
            }
          ]
        }
      }
    ];
  }

  public get destination() {
    const destinations: { [key: string]: any } = {
      VENDOR_TRANSFER: {
        placeholder: this.$tc("batch_transfer_manager_module.labels.vendor", 1),
        items:
          (this.destinationsScope.vendors.length &&
            this.destinationsScope.vendors) ||
          this.defaultListVendors
      },
      LOCATION_TRANSFER: {
        placeholder: this.$tc(
          "batch_transfer_manager_module.labels.location",
          1
        ),
        items:
          (this.destinationsScope.locations.length &&
            this.destinationsScope.locations) ||
          this.defaultListLocations
      }
    };
    return this.filters.destination_type
      ? destinations[this.filters.destination_type]
      : {
          placeholder: this.$t(
            "batch_transfer_manager_module.labels.select_type_first"
          ),
          items: []
        };
  }
  @Prop({ default: false }) public isActive!: boolean;
  public filters: { [key: string]: string | null } = {};
  public pagination: TablePagination = { ...TablePaginationDefault };
  public invoicesTypes: Array<{ label: string; value: string }> = [];
  public invoiceStatuses: Array<{ label: string; value: string }> = [];
  public searchField = "";
  public isLoading = false;
  public destinationTypes: Array<{
    label: string;
    value: TRANSFER_SOURCE;
  }> = [];
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  protected loaded: boolean = false;
  protected service!: InvoiceService;
  protected invoice: BatchTransfer.Invoice[] = [];
  protected currentSort: string | null = null;
  protected baseQuery: { [key: string]: string | number | string[] } = {
    sort: "-updated_at",
    "q[status_is_not_in]": [
      TRANSFER_STATUS.CLOSED,
      TRANSFER_STATUS.CANCELED,
      TRANSFER_STATUS.VOID
    ]
  };
  protected query: { [key: string]: string | number | string[] } = {
    ...this.baseQuery
  };
  private destinationsScope: BatchTransfer.FilterScopeTransfer =
    BatchTransfer.defaultFilterScope;

  public clearSourceFilter() {
    this.searchField = "";
    this.filters.source = null;
    this.filter();
  }

  public updateDestination() {
    if (this.filters.destination_type === TRANSFER_SOURCE.LOCATION_TRANSFER) {
      this.destinationsScope.locations = this.getterLocations;
    } else {
      this.destinationsScope.vendors = this.getterVendors;
    }
    this.isLoading = false;
  }

  public setInvoiceSource(item: BatchTransfer.Invoice): BatchTransfer.Invoice {
    if (item.transfer!.source_type === "VENDOR_TRANSFER") {
      item.transfer!.source = {
        ...this.defaultListVendors.filter(
          vendor => vendor.id === item.transfer!.source_id!
        )[0]
      } as Vendor;
    } else {
      item.transfer!.source = {
        ...this.defaultListLocations.filter(
          location => location.id === item.transfer!.source_id!
        )[0]
      } as Location;
    }
    return item;
  }

  @Watch("searchField")
  public async search(value: string) {
    if (value !== null) {
      this.isLoading = true;
      await this.loadDebounce(
        value,
        (this.filters.destination_type === TRANSFER_SOURCE.LOCATION_TRANSFER &&
          this.searchLocation) ||
          this.searchVendor,
        this.updateDestination
      );
    }
  }

  @Watch("isActive", { immediate: true }) public onActiveChange() {
    if (this.isActive) {
      this.$changes.watch(
        [pusherEvents.invoiceTouched, pusherEvents.transferTouched],
        this.fetchData
      );

      if (!this.loaded) {
        this.fetchData();
        this.loaded = true;
      }
    }
  }

  protected mounted() {
    EventBus.$on("BatchTransferListTouched", (transferType: string) => {
      if (
        transferType === "inbound-transfer" ||
        transferType === "outbound-transfer" ||
        transferType === "manifest"
      ) {
        this.loaded = false;
      }
    });
    this.tableDefs.subcomponent.field = "payments";
    this.service = invoiceService;
    this.invoicesTypes = [
      {
        label: String(
          this.$t("batch_transfer_manager_module.labels.invoice_types.incoming")
        ),
        value: "OUTBOUND"
      },
      {
        label: String(
          this.$t("batch_transfer_manager_module.labels.invoice_types.outgoing")
        ),
        value: "INBOUND"
      }
    ];
    this.invoiceStatuses = [
      {
        label: String(
          this.$t(
            "batch_transfer_manager_module.labels.invoice_statuses.COMPLETE"
          )
        ),
        value: "COMPLETED"
      },
      {
        label: String(
          this.$t(
            "batch_transfer_manager_module.labels.invoice_statuses.PENDING"
          )
        ),
        value: "PENDING"
      }
    ];
    this.destinationTypes = [
      {
        label: String(
          this.$tc("batch_transfer_manager_module.labels.vendor", 1)
        ),
        value: TRANSFER_SOURCE.VENDOR_TRANSFER
      },
      {
        label: String(
          this.$tc("batch_transfer_manager_module.labels.location", 1)
        ),
        value: TRANSFER_SOURCE.LOCATION_TRANSFER
      }
    ];
  }

  protected changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.applyFilters();
    this.loaded = true;
  }

  protected sort(header: TableHeader) {
    this.sortAndFetch(header, this.fetchData);
  }

  protected async applyFilters() {
    const dataToFetch: { [key: string]: any } = {
      "q[created_at_greater_than_or_equal]": this.filters.start_date,
      "q[created_at_less_than_or_equal]": this.filters.end_date,
      page: this.pagination.currentPage,
      per_page: this.pagination.itemsPerPage
    };

    if (this.filters.destination_type) {
      dataToFetch[
        "q[transfer.sourceable_type_equals]"
      ] = this.filters.destination_type;
    }

    if (this.filters.destination_id) {
      dataToFetch[
        "q[transfer.sourceable_id_equals]"
      ] = this.filters.destination_id;
    }

    if (this.filters.type) {
      dataToFetch["q[transfer.type_equals]"] = this.filters.type;
    }

    if (this.filters.status) {
      dataToFetch["q[status_equals]"] = this.filters.status;
    }

    this.query = { ...this.baseQuery, ...dataToFetch };
    await this.fetchData();
    this.tableDefs.loading = false;
  }

  protected async fetchData() {
    try {
      this.tableDefs.loading = true;
      const response = await this.service.getAll(this.query);
      this.pagination = response.pagination;
      this.rawData = response.data;
      this.mapData(invoicesTable, this.rawData);
      this.tableDefs.loading = false;
    } catch (e) {
      this.tableDefs.loading = false;
      this.rawData = [];
      messagesService.renderErrorMessage(e);
    }
  }
}
