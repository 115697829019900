import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import { pusherEvents } from "@/enums/pusherEvents";
import { EventBus } from "@/event-bus";
import { currencyFilter } from "@/filters/currency.filter";
import {
  BatchTransfer,
  MANIFEST_STATUS,
  TRANSFER_SOURCE,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import batchTransferManagerService from "@/services/BatchTransferManager/batchTransferManager.service";
import manifestService from "@/services/BatchTransferManager/Manifest.service";
import ManifestService from "@/services/BatchTransferManager/Manifest.service";
import { OutboundTransferService } from "@/services/BatchTransferManager/OutboundTransfer.service";
import { messagesService } from "@/services/messages.service";
import { FNS_DATE_FORMATS } from "@/utils/date-fns.utils";
import {
  HelixDatePickerComponent,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault,
  TableSubListComponent
} from "helix-vue-components";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ManifestModel } from "../../Manifest/Manifest.component";
import { emptyModel } from "../../Manifest/Manifest.component";
import {
  buildDestinationRoute,
  calcRoute,
  calculateEstimatedTime,
  parseAddress,
  parseLastStopArrival
} from "../../routing.utils";
import BatchTransferTableActions from "../BatchTransferTableActions.mixin";
import BatchTransferTableData from "../BatchTransferTableData.mixin";
import Template from "./OutboundTransfersDataTable.template.vue";
import { outboundTransferTable } from "./tableDefinition";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableSubListComponent,
    HelixDatePickerComponent
  },
  inject: ["$changes"]
})
export default class OutboundTransfersDataTable extends Mixins(
  BatchTransferTableData,
  BatchTransferTableActions,
  StepsData
) {
  public get rowActions() {
    return [
      {
        icon: "fal fa-ellipsis-v",
        modalActions: {
          modalNumber: 2,
          modalButtons: [
            {
              icon: "fal fa-file-invoice-dollar",
              action: (item: any) => {
                const transfer = this.rawData.find(t => item.id === t.id);
                this.printInvoice(transfer!);
              }
            },
            {
              icon: "fal fa-trash-alt",
              action: (item: BatchTransfer.OutboundTransfer) => {
                const transfer = this.rawData.find(
                  t => item.id === t.id
                ) as BatchTransfer.OutboundTransfer;
                if (
                  transfer.manifest &&
                  transfer.manifest.status === MANIFEST_STATUS.PENDING &&
                  transfer.manifest.destinations!.filter(
                    dest => dest.transfer!.status !== TRANSFER_STATUS.CANCELED
                  ).length > 1
                ) {
                  this.calculateRouteAndVoid(transfer);
                } else {
                  this.voidOutbound(transfer!.id!);
                }
              },
              visibleCondition: (transfer: BatchTransfer.OutboundTransfer) => {
                return (
                  this.hasPermission(this.policyList.voidOutboundTransfer) &&
                  (transfer.status === TRANSFER_STATUS.PENDING ||
                    transfer.status === TRANSFER_STATUS.NOT_ACCEPTED ||
                    transfer.status === TRANSFER_STATUS.COMPLETE)
                );
              }
            }
          ]
        }
      }
    ];
  }

  public get destination() {
    const destinations: { [key: string]: any } = {
      VENDOR_TRANSFER: {
        placeholder: this.$tc("batch_transfer_manager_module.labels.vendor", 1),
        items:
          (this.destinationsScope.vendors.length &&
            this.destinationsScope.vendors) ||
          this.defaultListVendors
      },
      LOCATION_TRANSFER: {
        placeholder: this.$tc(
          "batch_transfer_manager_module.labels.location",
          1
        ),
        items:
          (this.destinationsScope.locations.length &&
            this.destinationsScope.locations) ||
          this.defaultListLocations
      }
    };
    return this.filters.destination_type
      ? destinations[this.filters.destination_type]
      : {
          placeholder: this.$t(
            "batch_transfer_manager_module.labels.select_type_first"
          ),
          items: []
        };
  }
  public destinationTypes: Array<{
    label: string;
    value: TRANSFER_SOURCE;
  }> = [];
  public searchField = "";
  public isLoading = false;
  public filters: { [key: string]: string | null } = {};
  public pagination: TablePagination = { ...TablePaginationDefault };
  public currentSort: string | null = null;
  public query: { [key: string]: string | number } = {
    sort: "-updated_at",
    "q[status_noteq]": TRANSFER_STATUS.CANCELED
  };
  @Prop({ default: false }) public isActive!: boolean;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  protected loaded: boolean = false;
  protected service!: OutboundTransferService;
  private destinationsScope: BatchTransfer.FilterScopeTransfer =
    BatchTransfer.defaultFilterScope;

  public updateDestination() {
    if (this.filters.destination_type === TRANSFER_SOURCE.LOCATION_TRANSFER) {
      this.destinationsScope.locations = this.getterLocations;
    } else {
      this.destinationsScope.vendors = this.getterVendors;
    }
    this.isLoading = false;
  }

  @Watch("searchField")
  public async search(value: string) {
    if (value !== null) {
      this.isLoading = true;
      await this.loadDebounce(
        value,
        (this.filters.destination_type === TRANSFER_SOURCE.LOCATION_TRANSFER &&
          this.searchLocation) ||
          this.searchVendor,
        this.updateDestination
      );
    }
  }

  @Watch("isActive", { immediate: true }) public onActiveChange() {
    if (this.isActive) {
      this.$changes.watch(pusherEvents.transferTouched, this.fetchData);

      if (!this.loaded) {
        this.fetchData();
        this.loaded = true;
      }
    }
  }

  public clearSourceFilter() {
    this.filters.source = null;
    this.filter();
  }

  public sort(header: TableHeader) {
    this.sortAndFetch(header, this.fetchData);
  }
  public async loadItems(data: { index: number }) {
    if (!this.tableDefs.data[data.index].visited) {
      this.tableDefs.subcomponent.loading = true;
      const order = this.tableDefs.data[data.index];
      const detail: BatchTransfer.OutboundTransfer = await this.service.getById(
        String(order.id),
        {
          embed: "transferItems.batch,transferItems.batch.product"
        }
      );
      this.tableDefs.data[data.index].items = detail.items.map(item => {
        if (item.batch!) {
          if (
            this.bioTrackTraceEnabled &&
            item.batch!.biotrack_traceability_id! !== ""
          ) {
            item.batch_uid = item.batch!.biotrack_traceability_id!;
          } else {
            item.batch_uid = item.batch!.batch_uid;
          }
        }
        return {
          batch_id: item.batch_uid,
          product: item.product!.name,
          batch_type:
            (item.product!.batch_type && item.product!.batch_type.name) || "--",
          quantity: item.quantity,
          total: currencyFilter(item.prices.total)
        };
      });
      this.tableDefs.subcomponent.loading = false;
      this.tableDefs.data[data.index].visited = true;
    }
  }

  protected mounted() {
    EventBus.$on("BatchTransferListTouched", (transferType: string) => {
      if (transferType === "manifest") {
        this.loaded = false;
      }
    });
    this.service = batchTransferManagerService.service(
      "outbound-transfer"
    ) as OutboundTransferService;
    this.destinationTypes = [
      {
        label: String(
          this.$tc("batch_transfer_manager_module.labels.vendor", 1)
        ),
        value: TRANSFER_SOURCE.VENDOR_TRANSFER
      },
      {
        label: String(
          this.$tc("batch_transfer_manager_module.labels.location", 1)
        ),
        value: TRANSFER_SOURCE.LOCATION_TRANSFER
      }
    ];
  }

  protected changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.applyFilters();
    this.loaded = true;
  }

  protected async applyFilters() {
    const dataToFetch: { [key: string]: any } = {};

    dataToFetch[
      "q[updated_at_greater_than_or_equal]"
    ] = this.filters.start_date;
    dataToFetch["q[updated_at_less_than_or_equal]"] = this.filters.end_date;
    dataToFetch["q[sourceable_type_equals]"] = this.filters.destination_type;
    dataToFetch["q[sourceable_id_equals]"] = this.filters.destination_id;

    dataToFetch.page = this.pagination.currentPage;
    dataToFetch.per_page = this.pagination.itemsPerPage;

    this.query = { ...this.query, ...dataToFetch };

    await this.fetchData();
  }

  protected async fetchData() {
    this.showTableLoading();
    const response = await this.service.getAll(this.query);
    this.pagination = response.pagination;
    this.rawData = response.data;
    this.mapData(outboundTransferTable, this.rawData);
    this.hideTableLoading();
  }

  protected showTableLoading(): void {
    this.tableDefs.loading = true;
  }

  protected hideTableLoading(): void {
    this.tableDefs.loading = false;
  }

  protected async voidOutbound(id: number) {
    const response = await this.service.void(id, this.showTableLoading);

    if (response) {
      EventBus.$emit("BatchTransferListTouched", "outbound-transfer");
      await this.fetchData();
    }

    this.hideTableLoading();
  }

  protected async calculateRouteAndVoid(
    transfer: BatchTransfer.OutboundTransfer
  ) {
    const response = await Promise.all([
      this.service.void(transfer.id!, this.showTableLoading),
      this.getManifest(transfer.manifest.id!)
    ]);

    const wasTransferVoided = response[0];
    const manifest = response[1];

    if (!wasTransferVoided) {
      this.hideTableLoading();
      return;
    }

    let manifestUpdated: boolean = false;
    let isManualRouting: boolean = true;

    if (manifest !== null) {
      const removedDestination = manifest.destinations!.find(
        dest => dest.transfer_id === transfer.id
      );
      removedDestination!.estimated_arrival = parseLastStopArrival(
        removedDestination!.estimated_arrival,
        FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME
      );
      manifest.destinations = manifest.destinations!.filter(
        dest =>
          dest.transfer!.status !== TRANSFER_STATUS.CANCELED &&
          dest.transfer_id !== transfer.id!
      );
      manifest.destinations!.forEach(dest => {
        const destination: any = dest.transfer!.destination;
        dest.address = parseAddress(
          destination.address || destination.address1,
          destination.city,
          destination.state,
          destination.zip
        );
      });

      if (manifest.route !== null) {
        isManualRouting = false;
        manifestUpdated = await this.updateManifestWithRoute(
          manifest,
          removedDestination!
        );
      } else {
        manifestUpdated = await this.updateManifestManualRouting(
          manifest,
          removedDestination!
        );
      }
    }

    try {
      EventBus.$emit("BatchTransferListTouched", "outbound-transfer");
      await this.fetchData();
    } catch (e) {
      // Nothing to do
    }

    if (!manifestUpdated || isManualRouting) {
      const confirmMessage = isManualRouting
        ? "void_outbound_in_manual_manifest"
        : "void_outbound_manifest_error";
      this.showManualRouteConfirm(
        transfer,
        this.$t(
          `batch_transfer_manager_module.messages.${confirmMessage}`
        ).toString()
      );
    }
  }

  protected async getManifest(
    manifestId: number
  ): Promise<BatchTransfer.Manifest | null> {
    try {
      return await ManifestService.getById(manifestId);
    } catch (error) {
      return null;
    }
  }

  protected showManualRouteConfirm(
    transfer: BatchTransfer.OutboundTransfer,
    message: string
  ) {
    this.$modals
      .loadConfirmation({
        title: "VOID OUTBOUND TRANSFER",
        text: message,
        acceptButton: "yes",
        cancelButton: "no"
      })
      .then(response => {
        if (response) {
          this.$router.push({
            name: "manifest",
            params: { id: `${transfer.manifest.id}` }
          });
        }
      })
      .catch(error => {
        // Nothing to do
      });
  }

  protected async updateManifestManualRouting(
    manifest: BatchTransfer.Manifest,
    removedDestination: BatchTransfer.Destination
  ): Promise<boolean> {
    const manifestModel: ManifestModel = { ...emptyModel, ...manifest };
    manifestModel.destinations!.forEach(destination => {
      destination.estimated_arrival = parseLastStopArrival(
        destination.estimated_arrival,
        FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME
      );
    });
    manifestModel.destinations!.push({
      ...removedDestination!,
      _destroy: true
    });
    await manifestService.update(manifestModel);
    return true;
  }

  protected async updateManifestWithRoute(
    manifest: BatchTransfer.Manifest,
    removedDestination: BatchTransfer.Destination
  ): Promise<boolean> {
    try {
      const result = await calcRoute(
        manifest.location!,
        manifest.destinations!
      );
      const manifestModel: ManifestModel = this.setStepsRoutes(
        { ...emptyModel, ...manifest, legs: result.routes[0].legs },
        result.routes[0].legs
      );
      manifestModel.destinations!.forEach((destination, index) => {
        destination.route = manifestModel.stepsRoutes[index];
        destination.estimated_arrival = parseLastStopArrival(
          destination.estimated_arrival,
          FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
        );
      });
      calculateEstimatedTime(manifestModel);
      manifestModel.route = this.routeStringify(manifestModel);
      manifestModel.destinations!.push({
        ...removedDestination!,
        _destroy: true
      });
      await manifestService.update(manifestModel);
      return true;
    } catch (error) {
      messagesService.showMessage(
        "fa fa-exclamation-triangle",
        this.$t(
          "batch_transfer_manager_module.messages.route_not_found"
        ).toString(),
        "warning"
      );
      return false;
    }
  }

  protected setStepsRoutes(
    manifest: ManifestModel,
    legs: google.maps.DirectionsLeg[]
  ): ManifestModel {
    legs
      .filter(
        destination => destination.start_address !== destination.end_address
      )
      .forEach(destination => {
        const parsedRoute = buildDestinationRoute(destination);
        manifest.stepsRoutes = [...manifest.stepsRoutes, parsedRoute];
      });

    return manifest;
  }

  protected routeStringify(manifest: ManifestModel): string {
    return manifest.stepsRoutes.reduce(
      (route, parsedRoute) => `${route}${parsedRoute}`
    );
  }
}
