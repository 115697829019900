import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import { currencyFilter } from "@/filters/currency.filter";
import {
  BatchTransfer,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { EventBus, pusherEvents } from "@/internal";
import manifestService, {
  ManifestService
} from "@/services/BatchTransferManager/Manifest.service";
import { messagesService } from "@/services/messages.service";
import {
  HelixDatePickerComponent,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault,
  TableSubListComponent
} from "helix-vue-components";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import ManifestPrint from "../../Manifest/print/manifestPrint.component";
import BatchTransferTableActions from "../BatchTransferTableActions.mixin";
import BatchTransferTableData from "../BatchTransferTableData.mixin";
import Template from "./ManifestDataTable.template.vue";
import { manifestTable } from "./tableDefinition";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableSubListComponent,
    HelixDatePickerComponent
  },
  inject: ["$changes"]
})
export default class ManifestDataTable extends Mixins(
  BatchTransferTableData,
  BatchTransferTableActions,
  StepsData
) {
  public filters: { [key: string]: any } = {};
  public pagination: TablePagination = { ...TablePaginationDefault };
  @Prop({ default: false }) public isActive!: boolean;
  protected loaded: boolean = false;
  protected service!: ManifestService;
  protected manifests: BatchTransfer.Manifest[] = [];
  protected currentSort: string | null = null;
  protected query: { [key: string]: string | number } = {
    sort: "-updated_at",
    "q[status_noteq]": TRANSFER_STATUS.CANCELED
  };

  public get rowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-ellipsis-v",
        modalActions: {
          modalNumber: 2,
          modalButtons: [
            {
              icon: "far fa-box-check",
              action: (item: any) => {
                const transfer = this.rawData.find(t => item.id === t.id);
                this.completeManifest(transfer!.id);
              },
              visibleCondition: (item: any) => {
                return (
                  item.status !== TRANSFER_STATUS.COMPLETE &&
                  this.hasPermission(this.policyList.completeManifest)
                );
              }
            },
            {
              icon: "fal fa-print",
              visibleCondition: () =>
                this.hasPermission(this.policyList.printManifest),
              action: (item: any) => {
                const manifest = this.rawData.find(t => item.id === t.id);
                this.printManifest(manifest!.id!);
              }
            },
            {
              icon: "fal fa-pen",
              action: (item: any) => {
                this.$router.push({
                  name: "manifest",
                  params: { id: item.id }
                });
              },
              visibleCondition: (item: any) => {
                return (
                  item.status !== TRANSFER_STATUS.COMPLETE &&
                  this.hasPermission(this.policyList.modifyManifest)
                );
              }
            },
            {
              icon: "fal fa-trash-alt",
              action: (item: any) => {
                const transfer = this.rawData.find(t => item.id === t.id);
                this.voidManifest(transfer!.id!);
              },
              visibleCondition: (item: any) => {
                return (
                  item.status !== TRANSFER_STATUS.COMPLETE &&
                  this.hasPermission(this.policyList.voidManifest)
                );
              }
            }
          ]
        }
      }
    ];
  }

  @Watch("isActive", { immediate: true }) public onActiveChange() {
    if (this.isActive) {
      this.$changes.watch(
        [pusherEvents.manifestTouched, pusherEvents.transferTouched],
        this.fetchData
      );

      if (!this.loaded) {
        this.fetchData();
        this.loaded = true;
      }
    }
  }

  public async loadTransfers(event: { index: number; isOpening: boolean }) {
    if (!this.tableDefs.data[event.index].visited) {
      this.tableDefs.subcomponent.loading = true;
      const manifest: BatchTransfer.Manifest = await this.service.getById(
        +this.tableDefs.data[event.index].id
      );
      const destinations = manifest.destinations || [];
      this.tableDefs.data[event.index].destinations = destinations.map(
        destination => ({
          id: destination.id,
          batches_quantity:
            destination.transfer && destination.transfer.items
              ? destination.transfer!.items.length
              : 0,
          transfer_id: destination.transfer
            ? destination.transfer!.barcode
            : "",
          destination:
            destination.transfer && destination.transfer.destination
              ? destination.transfer.destination.name
              : "",
          user:
            destination.transfer && destination.transfer.user
              ? `${destination.transfer.user.first_name} ${
                  destination.transfer.user.last_name
                }`
              : "",
          total: destination.transfer
            ? currencyFilter(destination.transfer!.prices.total)
            : currencyFilter(0)
        })
      );
      this.tableDefs.subcomponent.loading = false;
      this.tableDefs.data[event.index].visited = true;
    }
  }

  protected changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.applyFilters();
    this.loaded = true;
  }

  protected sort(header: TableHeader) {
    this.sortAndFetch(header, this.fetchData);
  }

  protected mounted() {
    EventBus.$on("BatchTransferListTouched", (transferType: string) => {
      if (transferType === "outbound-transfer") {
        this.loaded = false;
      }
    });
    this.service = manifestService;
    this.tableDefs.subcomponent.field = "destinations";
  }

  protected async applyFilters() {
    const dataToFetch: { [key: string]: any } = {
      page: this.pagination.currentPage,
      per_page: this.pagination.itemsPerPage
    };
    dataToFetch["q[departure_greater_than_or_equal]"] = this.filters.departure;
    dataToFetch["q[arrival_less_than_or_equal]"] = this.filters.arrival;
    this.query = { ...this.query, ...dataToFetch };
    await this.fetchData();
    this.tableDefs.loading = false;
  }

  protected async fetchData() {
    this.tableDefs.loading = true;
    const response = await this.service.getAll(this.query);
    this.pagination = response.pagination;
    this.rawData = response.data;
    this.mapData(manifestTable, this.rawData);
    this.tableDefs.loading = false;
  }

  protected async voidManifest(id: number) {
    try {
      await this.service.void(id);
      EventBus.$emit("BatchTransferListTouched", "manifest");
      this.fetchData();
    } catch (e) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.something_went_wrong")
        ),
        "error"
      );
    }
  }

  protected async completeManifest(id: number) {
    try {
      await this.service.complete(id);
      EventBus.$emit("BatchTransferListTouched", "manifest");
      this.fetchData();
    } catch (e) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.something_went_wrong")
        ),
        "error"
      );
    }
  }
  protected async printManifest(id: number) {
    try {
      const manifest = await this.service.getById(id);
      EventBus.$emit("print", {
        component: ManifestPrint,
        props: {
          manifest
        }
      });
    } catch (e) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.something_went_wrong")
        ),
        "error"
      );
    }
  }
}
