import { currencyFilter } from "@/filters/currency.filter";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Product } from "@/interfaces/product";
import { TableFieldType } from "helix-vue-components";

export const purchaseOrdersTable: BatchTransfer.DataTable.TableDefs<
  BatchTransfer.PurchaseOrder
> = {
  headers: [
    {
      label: "purchase_order_number",
      value: "purchase_order_number",
      fieldComponent: TableFieldType.string,
      sortable: true
    },
    {
      label: "created_at",
      value: "timestamp",
      fieldComponent: TableFieldType.fnsDate
    },
    {
      label: "total",
      value: "total",
      fieldComponent: TableFieldType.string,
      sortable: true
    },
    {
      label: "user",
      value: "user",
      fieldComponent: TableFieldType.string
    },
    {
      label: "source",
      value: "vendor",
      fieldComponent: TableFieldType.string
    }
  ],
  sublistHeaders: [
    {
      label: "product",
      value: "product",
      fieldComponent: TableFieldType.string
    },
    {
      label: "quantity",
      value: "quantity",
      fieldComponent: TableFieldType.string
    },
    {
      label: "total",
      value: "total",
      fieldComponent: TableFieldType.string
    }
  ],
  rowActions: [
    {
      icon: "fal fa-print",
      action: BatchTransfer.DataTable.TABLEACTIONS.PRINT
    },
    {
      icon: "fal fa-pencil",
      action: BatchTransfer.DataTable.TABLEACTIONS.EDIT
    },
    { icon: "fal fa-trash", action: BatchTransfer.DataTable.TABLEACTIONS.VOID }
  ],
  mapper: (transfer: BatchTransfer.PurchaseOrder) => {
    return {
      id: transfer.id ? transfer.id : "",
      purchase_order_number: transfer.purchase_order_number,
      timestamp: transfer.updated_at,
      batch_quantity: String(transfer.items.length),
      total: currencyFilter(transfer.prices.total),
      user: transfer.user
        ? `${transfer.user.first_name} ${transfer.user.last_name}`
        : "",
      status: transfer.status,
      vendor: transfer.source ? transfer.source!.name : "",
      items: transfer.items.map(item => {
        return {
          product: (item.product as Product).name,
          quantity: item.quantity,
          total: currencyFilter(item.prices.total)
        };
      })
    };
  }
};
