import { PageNavAction } from "@/types/types";
import { BooleanCheck, Callback } from "helix-vue-components";
import { Component, Mixins } from "vue-property-decorator";
import { Action, Mutation } from "vuex-class";
import Template from "./BatchTransferList.template.vue";
import BatchTransferTableActions from "./BatchTransferTableActions.mixin";
import InboundTransfersDataTable from "./InboundTransfersDataTable/InboundTransfersDataTable.component";
import InvoicesDataTable from "./InvoicesDataTable/InvoicesDataTable.component";
import ManifestDataTable from "./ManifestDataTable/ManifestDataTable.component";
import OutboundTransfersDataTable from "./OutboundTransfersDataTable/OutboundTransfersDataTable.component";
import PurchaseOrdersDataTable from "./PurchaseOrdersDataTable/PurchaseOrdersDataTable.component";
import QuotesDataTable from "./QuotesDataTable/QuotesDataTable.component";

interface BatchTransferTab {
  text: string;
  component: string;
  visibleCondition?: BooleanCheck;
}

@Component({
  mixins: [Template],
  components: {
    InboundTransfersDataTable,
    InvoicesDataTable,
    ManifestDataTable,
    OutboundTransfersDataTable,
    PurchaseOrdersDataTable,
    QuotesDataTable
  }
})
export default class BatchTransferListComponent extends Mixins(
  BatchTransferTableActions
) {
  public availableTabs: BatchTransferTab[] = [];
  public activeTab: number = -1;
  @Mutation("setDefaultLocations", { namespace: "LocationModule" })
  private setDefaultLocations!: Callback;
  @Mutation("setDefaultVendors", { namespace: "VendorModule" })
  private setDefaultVendors!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  private setPageNav!: PageNavAction;

  public updateRoute(value: number) {
    const route = this.$route.path;
    this.$router.replace(
      `${
        this.$route.params.type
          ? route.replace(this.$route.params.type, this.tabKey(value))
          : `${route}/${this.tabKey(value)}`
      }`
    );
  }

  protected setupTabs() {
    this.availableTabs = [
      {
        text: "purchase-order",
        component: "PurchaseOrdersDataTable",
        visibleCondition: () =>
          this.hasPermission(this.policyList.viewPurchaseOrdersTab)
      },
      {
        text: "inbound-transfer",
        component: "InboundTransfersDataTable",
        visibleCondition: () =>
          this.hasPermission(this.policyList.viewInboundTransferTab)
      },
      {
        text: "quote",
        component: "QuotesDataTable",
        visibleCondition: () =>
          this.hasPermission(this.policyList.viewQuotesTab)
      },
      {
        text: "outbound-transfer",
        component: "OutboundTransfersDataTable",
        visibleCondition: () =>
          this.hasPermission(this.policyList.viewOutboundTransferTab)
      },
      {
        text: "invoice",
        component: "InvoicesDataTable",
        visibleCondition: () =>
          this.hasPermission(this.policyList.viewInvoicesTab)
      },
      {
        text: "manifest",
        component: "ManifestDataTable",
        visibleCondition: () =>
          this.hasPermission(this.policyList.viewManifestsTab)
      }
    ].filter(
      t => (t.visibleCondition && t.visibleCondition()) || !t.visibleCondition
    );
  }
  protected async created() {
    this.setupTabs();
    await this.searchVendor();
    await this.searchLocation();
    this.setDefaultLocations(this.getterLocations);
    this.setDefaultVendors(this.getterVendors);
  }
  protected mounted() {
    this.setPageNav({
      title: "batch_transfer_manager_module.titles.placeholder"
    });
    this.activeTab = Math.max(
      this.availableTabs.findIndex(
        (t: BatchTransferTab) => t.text === this.$route.params.type
      ),
      0
    );
  }
  protected tabKey(index: number) {
    return this.availableTabs[index].text;
  }
}
