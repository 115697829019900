import { currencyFilter } from "@/filters/currency.filter";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import {
  InvoiceDetailBatches,
  InvoiceDetailHeaders,
  InvoiceDetailPayments
} from "@/interfaces/invoiceDetails";
import { PaymentMethod } from "@/interfaces/retailSettings";
import { store } from "@/store";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { batchesHeaders, paymentsHeaders } from "./detailTableHeader";
import Template from "./InvoiceDataTableDetails.template.vue";
import InvoiceDataTableDetailsTableComponent from "./InvoiceDataTableDetailsTable/InvoiceDataTableDetailsTable.component";

@Component({
  mixins: [Template],
  components: {
    InvoiceDataTableDetailsTableComponent
  }
})
export default class InvoiceDataTableDetails extends Vue {
  @Prop() public invoice!: BatchTransfer.Invoice;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public batchId!: string;

  public batchesHeaders: InvoiceDetailHeaders[] = batchesHeaders;
  public paymentsHeaders: InvoiceDetailHeaders[] = paymentsHeaders;

  public get batches(): InvoiceDetailBatches[] {
    return this.invoice.transfer
      ? this.invoice.transfer.items.map(item => {
          if (item.batch!) {
            if (
              this.bioTrackTraceEnabled &&
              item.batch!.biotrack_traceability_id! !== ""
            ) {
              this.batchId = item.batch!.biotrack_traceability_id!;
            } else {
              this.batchId = item.batch!.batch_uid!;
            }
          }
          return {
            batch_uid: this.batchId,
            product_name: item.product!.name,
            batch_type_name: item.product!.batch_type!.name,
            quantity: item.quantity,
            price: currencyFilter(item.prices.total)
          };
        })
      : [];
  }

  public get payments(): InvoiceDetailPayments[] {
    const paymentMethods: PaymentMethod[] =
      store.getters["AuthModule/paymentMethods"];
    return this.invoice.payments
      ? this.invoice.payments.map(payment => {
          return {
            paid: currencyFilter(payment.applied),
            payment_date: fnsFormatDate(
              new Date(payment.created_at),
              FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME
            ),
            payment_method: this.setPaymentName(paymentMethods, payment.type)
          };
        })
      : [];
  }

  private setPaymentName(methods: PaymentMethod[], id: number): string {
    const method = methods.filter(p => p.id === id);

    if (method.length > 0) {
      return method[0]!.name || "-";
    }

    return "-";
  }
}
