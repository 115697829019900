import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import { currencyFilter } from "@/filters/currency.filter";
import {
  BatchTransfer,
  SourceType,
  TRANSFER_SOURCE,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { Product } from "@/interfaces/product";
import { EventBus, pusherEvents } from "@/internal";
import { ChangesNotificationService } from "@/plugins/changes-notifications/changes-notification.service";
import batchTransferManagerService from "@/services/BatchTransferManager/batchTransferManager.service";
import { PurchaseOrderService } from "@/services/BatchTransferManager/PurchaseOrder.service";
import { messagesService } from "@/services/messages.service";
import {
  BooleanCheck,
  HelixDatePickerComponent,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault,
  TableSubListComponent
} from "helix-vue-components";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import BatchTransferTableActions from "../BatchTransferTableActions.mixin";
import BatchTransferTableData from "../BatchTransferTableData.mixin";
import PurchaseOrderPrintComponent from "./print/PurchaseOrderPrint.component";
import Template from "./PurchaseOrdersDataTable.template.vue";
import { purchaseOrdersTable } from "./tableDefinitions";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableSubListComponent,
    HelixDatePickerComponent
  },
  inject: ["$changes"]
})
export default class PurchaseOrdersDataTable extends Mixins(
  BatchTransferTableData,
  BatchTransferTableActions,
  StepsData
) {
  public searchField = "";
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasCreatePermission!: BooleanCheck;
  public isLoading = false;
  public filters: { [key: string]: string | null } = {};
  public sourceTypes: SourceType[] = [];
  public pagination: TablePagination = { ...TablePaginationDefault };
  @Prop({ default: false }) public isActive!: boolean;
  protected loaded: boolean = false;
  protected currentSort: string | null = null;
  protected createPermission!: boolean;
  protected service!: PurchaseOrderService;
  protected query: { [key: string]: string | number | string[] } = {
    sort: "-updated_at",
    "q[status_is_not_in]": [TRANSFER_STATUS.CLOSED, TRANSFER_STATUS.CANCELED]
  };
  private sourceScope: BatchTransfer.FilterScopeTransfer =
    BatchTransfer.defaultFilterScope;
  public get rowActions() {
    return [
      {
        icon: "fal fa-ellipsis-v",
        modalActions: {
          modalNumber: 2,
          modalButtons: [
            {
              icon: "fal fa-print",
              visibleCondition: () =>
                this.hasPermission(this.policyList.printPurchaseOrder),
              action: (item: any) => {
                this.print(item, PurchaseOrderPrintComponent);
              }
            },
            {
              icon: "fal fa-pen",
              visibleCondition: () =>
                this.hasPermission(this.policyList.modifyPurchaseOrder),
              action: (item: any) => {
                const transfer = this.rawData.find(t => item.id === t.id);
                this.edit(transfer!);
              }
            },
            {
              icon: "fal fa-trash-alt",
              visibleCondition: () =>
                this.hasPermission(this.policyList.deletePurchaseOrder),
              action: (item: any) => {
                const transfer = this.rawData.find(t => item.id === t.id);
                this.void(transfer!.id!);
              }
            }
          ]
        }
      }
    ];
  }

  public get source() {
    const sources: { [key: string]: any } = {
      VENDOR_TRANSFER: {
        placeholder: this.$tc("batch_transfer_manager_module.labels.vendor", 1),
        items:
          (this.sourceScope.vendors.length && this.sourceScope.vendors) ||
          this.defaultListVendors
      },
      LOCATION_TRANSFER: {
        placeholder: this.$tc(
          "batch_transfer_manager_module.labels.location",
          1
        ),
        items:
          (this.sourceScope.locations.length && this.sourceScope.locations) ||
          this.defaultListLocations
      }
    };
    return this.filters.type
      ? sources[this.filters.type]
      : {
          placeholder: this.$t(
            "batch_transfer_manager_module.labels.select_type_first"
          ),
          items: []
        };
  }

  public clearSourceFilter() {
    this.searchField = "";
    this.filters.source = null;
    this.filter();
  }

  public sort(header: TableHeader) {
    this.sortAndFetch(header, this.fetchData);
  }
  public updateSource() {
    if (this.filters.type === TRANSFER_SOURCE.LOCATION_TRANSFER) {
      this.sourceScope.locations = this.getterLocations;
    } else {
      this.sourceScope.vendors = this.getterVendors;
    }
    this.isLoading = false;
  }

  @Watch("searchField")
  public async search(value: string) {
    if (value !== null) {
      this.isLoading = true;
      await this.loadDebounce(
        value,
        (this.filters.type === TRANSFER_SOURCE.LOCATION_TRANSFER &&
          this.searchLocation) ||
          this.searchVendor,
        this.updateSource
      );
    }
  }

  @Watch("isActive", { immediate: true }) public onActiveChange() {
    if (this.isActive) {
      this.$changes.watch(
        [pusherEvents.purchaseOrderTouched, pusherEvents.transferTouched],
        this.fetchData
      );

      if (!this.loaded) {
        this.fetchData();
        this.loaded = true;
      }
    }
  }

  public async loadItems(event: { index: number; isOpening: boolean }) {
    if (!this.tableDefs.data[event.index].visited) {
      this.tableDefs.subcomponent.loading = true;
      const order = this.tableDefs.data[event.index];
      const detail: BatchTransfer.PurchaseOrder | null = await this.service.getById(
        String(order.id),
        { embed: "purchaseOrderItems.product" }
      );
      if (detail) {
        this.tableDefs.data[event.index].items = detail.items.map(item => {
          return {
            product: (item.product as Product).name,
            quantity: item.quantity,
            total: currencyFilter(item.prices.total)
          };
        });
      }
      this.tableDefs.subcomponent.loading = false;
      this.tableDefs.data[event.index].visited = true;
    }
  }

  protected mounted() {
    this.createPermission = this.hasCreatePermission(
      this.policyList.createPurchaseOrder
    );
    EventBus.$on("BatchTransferListTouched", (transferType: string) => {
      if (transferType === "inbound-transfer") {
        this.loaded = false;
      }
    });
    this.service = batchTransferManagerService.service(
      "purchase-order"
    ) as PurchaseOrderService;
    this.sourceTypes = [
      {
        label: String(
          this.$tc("batch_transfer_manager_module.labels.vendor", 1)
        ),
        value: TRANSFER_SOURCE.VENDOR_TRANSFER
      },
      {
        label: String(
          this.$tc("batch_transfer_manager_module.labels.location", 1)
        ),
        value: TRANSFER_SOURCE.LOCATION_TRANSFER
      }
    ];
  }

  protected async applyFilters() {
    this.query = {
      ...this.query,
      "q[created_at_greater_than_or_equal]": this.filters.start_date!,
      "q[created_at_less_than_or_equal]": this.filters.end_date!,
      "q[sourceable_type_equals]": this.filters.type!,
      "q[sourceable_id_equals]": this.filters.source!,
      page: this.pagination.currentPage,
      per_page: this.pagination.itemsPerPage
    };
    await this.fetchData();
    this.tableDefs.loading = false;
  }

  protected changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.applyFilters();
    this.loaded = true;
  }

  protected async fetchData() {
    this.tableDefs.loading = true;
    const response = await this.service.getAll(this.query);
    this.pagination = response.pagination;
    this.rawData = response.data;
    this.mapData(purchaseOrdersTable, this.rawData);
    this.tableDefs.loading = false;
  }

  protected async void(id: number) {
    try {
      await this.service.void(id);
      this.fetchData();
    } catch (e) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.something_went_wrong")
        ),
        "error"
      );
    }
  }
}
