import { InvoiceDetailHeaders } from "@/interfaces/invoiceDetails";
import { i18n } from "@/plugins/i18n";

export const batchesHeaders: InvoiceDetailHeaders[] = [
  {
    label: i18n
      .t("batch_transfer_manager_module.table_headers.batch_id")
      .toString(),
    value: "batch_uid"
  },
  {
    label: i18n
      .t("batch_transfer_manager_module.table_headers.product")
      .toString(),
    value: "product_name"
  },
  {
    label: i18n
      .t("batch_transfer_manager_module.table_headers.batch_type")
      .toString(),
    value: "batch_type_name"
  },
  {
    label: i18n
      .t("batch_transfer_manager_module.table_headers.quantity")
      .toString(),
    value: "quantity"
  },
  {
    label: i18n
      .t("batch_transfer_manager_module.table_headers.price")
      .toString(),
    value: "price"
  }
];

export const paymentsHeaders: InvoiceDetailHeaders[] = [
  {
    label: i18n
      .t("batch_transfer_manager_module.table_headers.payment_method")
      .toString(),
    value: "payment_method"
  },
  {
    label: i18n
      .t("batch_transfer_manager_module.table_headers.paid")
      .toString(),
    value: "paid"
  },
  {
    label: i18n
      .t("batch_transfer_manager_module.table_headers.payment_date")
      .toString(),
    value: "payment_date"
  }
];
