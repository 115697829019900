import VueBarcode from "vue-barcode";

import { Component, Mixins, Prop } from "vue-property-decorator";
import Template from "./InvoicePrint.template.vue";

import ComputeTransfer from "@/components/inventory/BatchTransfer/BatchTransferManager/ComputeTransfer.mixin";
import {
  BatchTransfer,
  TRANSFER_TYPES
} from "@/interfaces/batchTransferManager";

import batchTransferManagerService from "@/services/BatchTransferManager/batchTransferManager.service";

@Component({
  mixins: [Template],
  components: {
    VueBarcode
  }
})
export default class InvoicePrint extends Mixins(ComputeTransfer) {
  @Prop() public invoice!: BatchTransfer.Invoice;
  public calculatedTransfer: BatchTransfer.Transfer | null = null;
  public groupedPayments: Array<{
    id: number;
    value: number;
    name: string;
  }> = [];

  public get transfer() {
    return this.calculatedTransfer;
  }
  public get payments() {
    return this.groupedPayments.filter(payment => payment.value > 0);
  }
  public get numberOfBatches() {
    return this.transfer!.items.length;
  }
  public get buyer() {
    if (this.transfer!.transfer_type === TRANSFER_TYPES.INBOUND) {
      return (this.transfer! as BatchTransfer.InboundTransfer).location;
    } else {
      return (this.transfer! as BatchTransfer.OutboundTransfer).destination;
    }
  }
  public get seller() {
    if (this.transfer!.transfer_type === TRANSFER_TYPES.INBOUND) {
      return (this.transfer! as BatchTransfer.InboundTransfer).source;
    } else {
      return (this.transfer! as BatchTransfer.OutboundTransfer).location;
    }
  }
  public get barcodeStyles() {
    return {
      height: 24,
      lineColor: "#525252"
    };
  }
  protected async mounted() {
    this.groupedPayments = await batchTransferManagerService.getPaymentMethods();
    this.groupedPayments.forEach(payment => (payment.value = 0));
    this.calculatedTransfer = this.recalculateAll(this.invoice.transfer!);

    this.invoice.payments.forEach(invoicePayment => {
      const relatedPayment = this.groupedPayments.find(
        p => p.id === invoicePayment.type
      );
      if (relatedPayment) {
        relatedPayment.value += Number(invoicePayment.applied);
      }
    });
    this.$emit("readyToPrint");
  }
}
