import { policyList } from "@/enums/permissions";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { Callback } from "@/types/types";
import { BooleanCheck } from "helix-vue-components";
import debounce from "lodash/debounce";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
@Component({})
export default class BatchTransferTableActions extends Vue {
  @Action("searchLocation", { namespace: "LocationModule" })
  public searchLocation!: Callback;
  @Getter("locations", { namespace: "LocationModule" })
  public getterLocations!: BatchTransfer.List[];
  @Getter("defaultLocations", { namespace: "LocationModule" })
  public defaultListLocations!: BatchTransfer.List[];
  @Action("searchVendors", { namespace: "VendorModule" })
  public searchVendor!: Callback;
  @Getter("vendors", { namespace: "VendorModule" })
  public getterVendors!: BatchTransfer.List[];
  @Getter("defaultVendors", { namespace: "VendorModule" })
  public defaultListVendors!: BatchTransfer.List[];
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public policyList = policyList;
  public actions: { [key: string]: Callback } = {
    new: this.newTransfer,
    edit: this.edit,
    print: this.print,
    void: this.void,
    "print-invoice": this.printInvoice
  };
  protected transferTypes: { [key: string]: string } = {
    PURCHASE_ORDER: "purchase-order",
    QUOTE: "quote",
    INBOUND: "inbound-transfer",
    OUTBOUND_TRANSFER: "outbound-transfer",
    NEW_BATCH: "new-batch"
  };

  protected loadDebounce = debounce(
    async (value: string, getterItems: Callback, updateSource: Callback) => {
      await getterItems(value);
      updateSource();
    },
    500
  );

  protected newTransfer(type: string) {
    this.$router.push({ name: "transfer-manager", params: { type } });
  }

  protected edit(transfer: BatchTransfer.Transfer) {
    this.$router.push({
      name: "transfer-manager-edit",
      params: {
        type: this.transferTypes[transfer.transfer_type],
        id: String(transfer.id)
      }
    });
  }
  protected print(transfer: BatchTransfer.Transfer, component: any) {
    EventBus.$emit("print", {
      component,
      props: {
        data: transfer
      }
    });
  }
  protected void(id: number) {
    messagesService.showMessage(
      "fal fa-exclamation-triangle",
      `Void transfer not implemented yet`,
      "warning"
    );
  }

  protected printInvoice(transfer: BatchTransfer.Transfer) {
    messagesService.showMessage(
      "fal fa-check",
      `Print invoice not implemented yet`,
      "success"
    );
  }
}
