import { currencyFilter } from "@/filters/currency.filter";
import {
  BatchTransfer,
  TRANSFER_SOURCE
} from "@/interfaces/batchTransferManager";
import { Product } from "@/interfaces/product";
import { i18n } from "@/plugins/i18n";
import { TableFieldType } from "helix-vue-components";

export const outboundTransferTable: BatchTransfer.DataTable.TableDefs<
  BatchTransfer.OutboundTransfer
> = {
  headers: [
    {
      label: "transfer_id",
      value: "transfer_id",
      fieldComponent: TableFieldType.string,
      sortable: true
    },
    {
      label: "timestamp",
      value: "timestamp",
      fieldComponent: TableFieldType.fnsDate
    },
    {
      label: "total",
      value: "total",
      fieldComponent: TableFieldType.string,
      sortable: true
    },
    {
      label: "user",
      value: "user",
      fieldComponent: TableFieldType.string
    },
    {
      label: "transfer_type",
      value: "transfer_type",
      fieldComponent: TableFieldType.string
    },
    {
      label: "destination",
      value: "destination",
      fieldComponent: TableFieldType.string
    },
    {
      label: "status",
      value: "parsed_status",
      fieldComponent: TableFieldType.string,
      sortable: true
    }
  ],
  sublistHeaders: [
    {
      label: "batch_id",
      value: "batch_id",
      fieldComponent: TableFieldType.string
    },
    {
      label: "product",
      value: "product",
      fieldComponent: TableFieldType.string
    },
    {
      label: "batch_type",
      value: "batch_type",
      fieldComponent: TableFieldType.string
    },
    {
      label: "quantity",
      value: "quantity",
      fieldComponent: TableFieldType.string
    },
    {
      label: "price",
      value: "total",
      fieldComponent: TableFieldType.string
    }
  ],
  mapper: (transfer: BatchTransfer.OutboundTransfer) => {
    return {
      id: transfer.id ? transfer.id : "",
      transfer_id: transfer.barcode,
      timestamp: transfer.updated_at,
      total: currencyFilter(transfer.prices.total),
      quantity: 0,
      user: transfer.user
        ? `${transfer.user.first_name} ${transfer.user.last_name}`
        : "",
      manifest: transfer.manifest,
      destination_type: transfer.destination_type,
      destination: transfer.destination ? transfer.destination.name : "-",
      status: transfer.status,
      parsed_status: transfer.status.replace("_", " "),
      items: transfer.items.map(i => {
        return {
          batch_uid: i.batch_uid,
          product: (i.product as Product).name,
          batch_type: (i.product as Product).type,
          quantity: i.quantity,
          price: i.prices.cost_per_unit
        };
      }),
      transfer_type:
        transfer.destination_type === TRANSFER_SOURCE.LOCATION_TRANSFER
          ? i18n.tc("batch_transfer_manager_module.labels.location", 1)
          : i18n.tc("batch_transfer_manager_module.labels.vendor", 1)
    };
  }
};
