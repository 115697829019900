import { currencyFilter } from "@/filters/currency.filter";
import { Batch, InventoryBatch } from "@/interfaces/batch";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Product } from "@/interfaces/product";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { TableFieldType } from "helix-vue-components";

export const inboundTransferTable: BatchTransfer.DataTable.TableDefs<
  BatchTransfer.InboundTransfer
> = {
  headers: [
    {
      label: "transfer_id",
      value: "transfer_id",
      fieldComponent: TableFieldType.string,
      sortable: true
    },
    {
      label: "timestamp",
      value: "timestamp",
      fieldComponent: TableFieldType.fnsDate,
      sortable: true
    },
    {
      label: "total",
      value: "total",
      fieldComponent: TableFieldType.string,
      sortable: true
    },
    {
      label: "user",
      value: "user",
      fieldComponent: TableFieldType.string
    },
    {
      label: "transfer_type",
      value: "transfer_type",
      fieldComponent: TableFieldType.string
    },
    {
      label: "source",
      value: "source",
      fieldComponent: TableFieldType.string
    }
  ],
  sublistHeaders: [
    {
      label: "batch_id",
      value: "batch_id",
      fieldComponent: TableFieldType.string
    },
    {
      label: "product",
      value: "product",
      fieldComponent: TableFieldType.string
    },
    {
      label: "batch_type",
      value: "batch_type",
      fieldComponent: TableFieldType.string
    },
    {
      label: "quantity",
      value: "quantity",
      fieldComponent: TableFieldType.string
    },
    { label: "price", value: "total", fieldComponent: TableFieldType.string }
  ],
  mapper: (transfer: BatchTransfer.InboundTransfer) => {
    const bioTrackTraceEnabled =
      store.getters["AuthModule/bioTrackTraceEnabled"];
    const location = store.getters["AuthModule/currentLocation"];
    const source =
      location.id === transfer.source_id
        ? i18n.t(
            "batch_transfer_manager_module.table_headers.existing_inventory"
          )
        : transfer.source
        ? transfer.source.name
        : "";
    return {
      id: transfer.id ? transfer.id : "",
      is_voidable: transfer.is_voidable,
      transfer_id: transfer.barcode,
      timestamp: transfer.updated_at,
      total: currencyFilter(transfer.prices.total),
      user: transfer.user
        ? `${transfer.user.first_name} ${transfer.user.last_name}`
        : "",
      source,
      transfer_type:
        transfer.sourceable_transfer_type &&
        transfer
          .sourceable_transfer_type!.split("_")
          .map(word => word.toLowerCase())
          .map(word => word[0].toUpperCase() + word.substring(1))
          .map(word => {
            if (word === "With") {
              return "from";
            } else {
              return word;
            }
          })
          .join(" "),
      status: transfer.status,
      source_type: transfer.source_type,
      location_id: transfer.location_id,
      source_id: transfer.source_id,
      items: transfer.items.map(i => {
        return {
          id: i.id,
          batch_uid: i.batch_uid,
          biotrack_traceability_id:
            (bioTrackTraceEnabled &&
              i.batch &&
              (i.batch as InventoryBatch).biotrack_traceability_id) ||
            "",
          new_product: i.product,
          product: (i.product as Product).name,
          batch_type: (i.product as Product).batch_type,
          quantity: i.quantity,
          unit: i.product!.unit,
          price: i.prices.cost_per_unit,
          new_batch_id:
            (i.batch && (i.batch as InventoryBatch).biotrack_traceability_id) ||
            ""
        };
      })
    };
  }
};
