import {
  BatchTransfer,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { i18n } from "@/plugins/i18n";
import {
  TableAction,
  TableActiveSubComponent,
  TableHeader
} from "helix-vue-components";
import debounce from "lodash/debounce";
import { Component, Vue } from "vue-property-decorator";

interface TableDefs {
  headers: TableHeader[];
  subcomponent: TableActiveSubComponent & { loading: boolean };
  data: Array<{ [key: string]: string | any[] | boolean }>;
  rowActions: TableAction[];
  loading: boolean;
}

@Component({})
export default class BatchTransferTableData extends Vue {
  public rawData: any[] = [];
  public tableDefs: TableDefs = {
    headers: [],
    subcomponent: {
      position: null,
      component: "TableSubListComponent",
      rowActions: [],
      field: "items",
      loading: false
    },
    rowActions: [],
    data: [],
    loading: false
  };
  protected debouncer = debounce(this.applyFilters, 2500);
  protected currentSort: string | null = null;
  protected query: { [key: string]: string | number | string[] } = {
    sort: "-updated_at",
    "q[status_is_not_in]": [TRANSFER_STATUS.CLOSED, TRANSFER_STATUS.CANCELED]
  };
  public filter() {
    this.tableDefs.loading = true;
    this.debouncer();
  }
  protected async applyFilters() {
    this.tableDefs.loading = false;
  }
  protected mapData(
    defs: BatchTransfer.DataTable.TableDefs<any>,
    data: BatchTransfer.Transfer[]
  ) {
    this.tableDefs.headers = defs.headers.map(h => {
      return {
        ...h,
        ...{
          label: i18n
            .t(`batch_transfer_manager_module.table_headers.${h.label}`)
            .toString()
        }
      };
    });
    if (defs.sublistHeaders) {
      this.tableDefs.subcomponent = {
        ...this.tableDefs.subcomponent,
        ...{
          fieldsToShow: defs.sublistHeaders.map(o => {
            return {
              label: i18n
                .t(`batch_transfer_manager_module.table_headers.${o.label}`)
                .toString(),
              value: o.value
            };
          })
        }
      };
    }
    this.tableDefs.data = data.map(row => defs.mapper(row));
    return this.tableDefs;
  }

  protected sortAndFetch(header: TableHeader, executer: () => void) {
    const h = { ...header };

    switch (h.value) {
      case "timestamp":
        h.value = "created_at";
        break;
      case "quote_number":
      case "manifest_id":
      case "invoice_id":
      case "purchase_order_number":
        h.value = "barcode";
        break;
      default:
        break;
    }

    if (!this.currentSort) {
      this.currentSort = h.value;
    }

    if (this.currentSort.includes(h.value)) {
      this.currentSort = this.currentSort.startsWith("-")
        ? h.value
        : `-${h.value}`;
    } else {
      this.currentSort = h.value;
    }

    this.query = { ...this.query, sort: this.currentSort };
    executer();
  }
}
