import { currencyFilter } from "@/filters/currency.filter";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { i18n } from "@/plugins/i18n";
import { TableFieldType } from "helix-vue-components";

export const invoicesTable = {
  headers: [
    {
      label: "invoice_id",
      value: "barcode",
      fieldComponent: TableFieldType.string,
      sortable: true
    },
    {
      label: "timestamp",
      value: "timestamp",
      fieldComponent: TableFieldType.fnsDate
    },
    {
      label: "destination_sender",
      value: "destination_sender",
      fieldComponent: TableFieldType.string
    },
    {
      label: "invoice_type",
      value: "invoice_type",
      fieldComponent: TableFieldType.string
    },
    {
      label: "total",
      value: "total",
      fieldComponent: TableFieldType.string
    },
    {
      label: "total_paid",
      value: "total_paid",
      fieldComponent: TableFieldType.string
    },
    {
      label: "outstanding",
      value: "outstanding",
      fieldComponent: TableFieldType.string
    }
  ],
  sublistHeaders: [],
  rowActions: [],
  mapper: (invoice: BatchTransfer.Invoice) => {
    return {
      id: invoice.id,
      barcode: invoice.barcode,
      timestamp: invoice.updated_at,
      status: invoice.status,
      invoice_type: String(
        i18n.t(
          `batch_transfer_manager_module.labels.invoice_types.${
            invoice.transfer!.transfer_type
          }`
        )
      ),
      destination_sender:
        invoice.transfer && invoice.transfer.transfer_type === "OUTBOUND"
          ? invoice.transfer &&
            invoice.transfer.destination &&
            (invoice.transfer.destination.name || "")
          : invoice.transfer &&
            invoice.transfer.source &&
            (invoice.transfer.source.name || ""),
      total_paid: currencyFilter(invoice.total - invoice.outstanding),
      total: currencyFilter(invoice.total),
      outstanding:
        invoice.status === "COMPLETED"
          ? "COMPLETED"
          : currencyFilter(invoice.outstanding),
      invoice
    };
  }
};
