import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { TableFieldType } from "helix-vue-components";

export const manifestTable = {
  headers: [
    {
      label: "manifest_id",
      value: "barcode",
      fieldComponent: TableFieldType.string,
      sortable: true
    },
    {
      label: "departure",
      value: "start_time",
      fieldComponent: TableFieldType.string
    },
    {
      label: "arrival",
      value: "end_time",
      fieldComponent: TableFieldType.string
    },
    {
      label: "driver",
      value: "driver",
      fieldComponent: TableFieldType.string
    },
    {
      label: "vehicle",
      value: "vehicle",
      fieldComponent: TableFieldType.string
    },
    {
      label: "stops",
      value: "stops",
      fieldComponent: TableFieldType.string
    },
    {
      label: "status",
      value: "status",
      fieldComponent: TableFieldType.string,
      sortable: true
    }
  ],
  sublistHeaders: [
    {
      label: "transfer",
      value: "transfer_id",
      fieldComponent: TableFieldType.string
    },
    {
      label: "destination",
      value: "destination",
      fieldComponent: TableFieldType.string
    },
    {
      label: "user",
      value: "user",
      fieldComponent: TableFieldType.string
    },
    {
      label: "batches_quantity",
      value: "batches_quantity",
      fieldComponent: TableFieldType.string
    },
    {
      label: "total",
      value: "total",
      fieldComponent: TableFieldType.string
    }
  ],
  mapper: (manifest: BatchTransfer.Manifest) => {
    return {
      id: manifest.id!,
      barcode: manifest.barcode,
      start_time: `${manifest.start_date} ${manifest.start_time}`,
      end_time: `${manifest.end_date} ${manifest.end_time}`,
      driver: manifest.driver
        ? `${manifest.driver.firstname} ${manifest.driver.lastname}`
        : "",
      vehicle: manifest.vehicle
        ? `${manifest.vehicle.model} ${manifest.vehicle.license_plate}`
        : "",
      stops: String(manifest.destinations!.length),
      status: manifest.status,
      destinations: []
    };
  }
};
