import { render, staticRenderFns } from "./InvoiceDataTableDetailsTable.template.vue?vue&type=template&id=c47cbdf2&scoped=true&"
var script = {}
import style0 from "./InvoiceDataTableDetailsTable.template.vue?vue&type=style&index=0&id=c47cbdf2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c47cbdf2",
  null
  
)

export default component.exports