import {
  InvoiceDetailBatches,
  InvoiceDetailHeaders,
  InvoiceDetailPayments
} from "@/interfaces/invoiceDetails";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./InvoiceDataTableDetailsTable.template.vue";

@Component({
  mixins: [Template]
})
export default class InvoiceDataTableDetailsTable extends Vue {
  @Prop({ default: true }) public showHeaders!: boolean;
  @Prop() public headers!: InvoiceDetailHeaders[];
  @Prop() public data!: InvoiceDetailBatches[] | InvoiceDetailPayments[];
}
