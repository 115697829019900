import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import InventoryLabelListComponent from "@/components/sharedComponents/print/inventoryLabelList/InventoryLabelList.component";
import { EventBus } from "@/event-bus";
import { PrintDataTable } from "@/interfaces/batch";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { PrintLabelTableMetadata } from "@/metadata/printLabel";
import { i18n } from "@/plugins/i18n";
import { PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  TableComponent,
  TableFieldType,
  TableHeader,
  TableItem,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PrintLabel.template.vue";

export enum TABLEACTIONS {
  EDIT,
  DELETE
}

@Component({
  mixins: [Template],
  components: {
    ActionsSubheaderComponent,
    TableComponent
  }
})
export default class PrintLabel extends Vue {
  public get rowActions() {
    return [
      {
        icon: "fal fa-barcode-alt",
        tooltip: String(i18n.t("print_barcode")),
        action: this.printBarcode
      },
      {
        icon: "fal fa-print",
        tooltip: String(i18n.t("print_label.print_inventory_lable")),
        action: this.printLabel
      }
    ];
  }
  public get tableData() {
    this.pagination.totalItems = this.details.length;
    return (
      (this.pagination &&
        chunk(this.details, this.pagination.itemsPerPage)[
          this.pagination.currentPage - 1
        ]) ||
      []
    );
  }

  public details: PrintDataTable[] = [];
  public isSaving = false;
  public headers: TableHeader[] = PrintLabelTableMetadata;
  public pagination: TablePagination = {
    totalItems: 0,
    itemsPerPage: 5,
    lastPage: 20,
    itemsPerPageOptions: [5, 10, 15, 20],
    currentPage: 1,
    from: 1,
    to: 3
  };
  public paginationDefault: TablePagination = cloneDeep(this.pagination);
  public isSelectedItem = false;
  public numOfSelectedItems = 0;
  public selectedItems: PrintDataTable[] = [];
  public noOfStrain = 0;

  @Prop() protected batches!: BatchTransfer.Batch[];
  @Prop({ default: () => [] }) protected cellsToHide!: string[];
  @Action("setPageNav", { namespace: "PageNavModule" })
  private setPageNav!: PageNavAction;

  public selectedBatch(data: PrintDataTable) {
    const index = this.details.findIndex(item => item.id === data.id);
    return index;
  }
  public onSelectItem(data: TableItem) {
    if (data.eventItem) {
      if (data.eventItem.status === true) {
        this.numOfSelectedItems++;
        if (!this.selectedItems.includes(data.eventItem.item)) {
          this.selectedItems.push(data.eventItem.item);
        }
      } else if (data.eventItem.status === false) {
        const indexUnchecked = this.selectedItems.indexOf(data.eventItem.item);
        this.numOfSelectedItems--;
        if (indexUnchecked > -1) {
          this.selectedItems.splice(indexUnchecked, 1);
        }
      }

      const index = this.selectedBatch(data.eventItem.item);
      if (index !== -1) {
        this.details[index].is_selected = data.eventItem.status;
      }
    } else {
      data.currentSelection.map((items: PrintDataTable) => {
        const index = this.selectedBatch(items);
        if (index !== -1) {
          this.details[index].is_selected = data.checkAll;
        }
      });
    }
    // untill the number of checked items are null the buttons will be enabled
    if (this.numOfSelectedItems > 0) {
      this.isSelectedItem = true;
    } else {
      this.isSelectedItem = false;
    }
  }
  public printBarcode(item: PrintDataTable) {
    EventBus.$emit("print", {
      component: BarcodeListComponent,
      props: {
        batches: [item]
      }
    });
  }

  public printLabel(item: PrintDataTable) {
    EventBus.$emit("print", {
      component: InventoryLabelListComponent,
      props: {
        batches: [item],
        labelType: "INVENTORY"
      }
    });
  }

  public onSort(header: TableHeader) {
    this.details = orderBy(
      this.details,
      header.value,
      header.descending ? "desc" : "asc"
    );
  }

  public finish() {
    this.$router.push({
      name: "batch-transfer",
      params: { type: "inbound-transfer" }
    });
  }

  public onChangePage(pagination: TablePagination) {
    this.pagination.currentPage = pagination.currentPage;
    const from =
      (pagination.currentPage - 1) * this.pagination.itemsPerPage + 1;
    const to =
      (pagination.currentPage - 1) * this.pagination.itemsPerPage +
      this.tableData.length;
    this.pagination.totalItems = this.details.length;
    this.pagination.from = (from > 0 && from) || 0;
    this.pagination.to = (to > 0 && to) || 0;
  }

  /**
   * Fills data table. Parses data to match table structure.
   */
  protected fillDataTable(item: any) {
    const newbatchPrint = true;
    const len = item.items.length;
    this.details = item.items.map((data: PrintDataTable) => {
      if (data.new_product.strain) {
        this.noOfStrain++;
      }
      return {
        id: data.id,
        product: data.new_product,
        product_variant: data.new_product,
        product_name: data.new_product.name
          ? data.new_product.name + `(${data.quantity + data.new_product.unit})`
          : "",
        product_category: data.new_product.category
          ? data.new_product.category!.name
          : "",
        batch_type: data.new_product.batch_type!.name,
        strain: data.new_product.strain ? data.new_product.strain.name : "",
        quantity: data.quantity,
        batch_uid: data.batch_uid,
        biotrack_traceability_id: data.new_batch_id,
        weighable: data.new_product.unit === "g" ? 1 : 0,
        new_batch_print: newbatchPrint
      };
    });
    if (this.details.length < 5) {
      this.pagination.to = this.details.length;
    } else {
      this.pagination.to = 5;
    }
  }

  protected async beforeMount() {
    if (!this.$route.params.data) {
      this.finish();
    }
  }

  protected mounted() {
    this.setPageNav({
      title: "batch_transfer_manager_module.titles.print_labels",
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-times",
            action: this.finish,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.fillDataTable(this.$route.params.data);
    if (this.noOfStrain === 0) {
      this.headers.splice(3, 1);
    } else {
      this.headers[3] = {
        value: "strain",
        label: i18n.t("print_label.strain").toString(),
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong",
        sortable: true
      };
      this.headers[4] = {
        value: "quantity",
        label: i18n.t("print_label.quantity").toString(),
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong",
        sortable: true
      };
      this.headers[5] = {
        value: "batch_uid",
        label: i18n.t("print_label.old_batch_id").toString(),
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong",
        sortable: true
      };
      this.headers[6] = {
        value: "biotrack_traceability_id",
        label: i18n.t("print_label.new_batch_id").toString(),
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong",
        sortable: true
      };
    }
  }
}
